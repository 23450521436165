// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import { cfdiScripts } from "../../../scripts/cfdi"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Complemento de notarios</h2>
                <p>
                    A diferencia del complemento de pago, que es una factura individual, <b>el complemento de notarios es una extensión del CFDI normal que mandas,</b> por lo que es un CFDI normal con mas campos.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/cfdi<b>/stamp</b>/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos del Request
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>lugarExpedicion:</b>Código postal del emisor de la factura, este campo sobrescribe el valor del perfil del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>serie:</b> Serie de la factura de complemento de pago.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>folio:</b> Folio de la factura de complemento de pago, igual que las facturas normales.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>receptor:</b> Un solo objeto que representa al receptor con las siguientes características.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.nombre:</b> Razón social del receptor de la factura.
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.rfc:</b> RFC del receptor de la factura (Ejemplo muestra RFC de venta a mostrador).
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.usoCFDI:</b> Clave del uso de lafactura. <em>Consultar catálogo SAT</em>
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p>
                            <b>notariosPublicos:</b> El objecto de complemento de notarios.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">notariosPublicos</span>.inmuebles: [ ]</b> Bloque de los inmuebles tramitados.
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.calle:</b> Calle donde se ubica el inmueble.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.tipoInmueble:</b> Clave del catalogo de SAT para imuebles, <code>"01" | "02"| "03" | "04" | "05"</code>
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.municipio:</b> Municipio en texto libre donde se ubica el inmueble.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.estado:</b> Clave del catalogo del SAT para estados <code>"01"| ... |"32"</code>.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.pais:</b> Clave del catalogo del SAT para paises <code>"MEX"</code>.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.codigoPostal:</b> Código postal donde se ubica el inmueble.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.colonia:</b> Colonia en texto libre donde se ubica el inmueble.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.noExterior: (opcional)</b> Número exterior del inmueble.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.noInterior: (opcional)</b> Número noInterior del inmueble.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.referencia: (opcional)</b> Referencia de la dirección.
                                        </p>    
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.localidad: (opcional)</b> Localidad o información adicional de dirección al inmueble.
                                        </p>    
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">notariosPublicos</span>.operacion:</b> Objecto con la referencia del instrumento notarial.
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.fechaNotarial: </b> Fecha en la que se elaboró el instrumento notarial.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.inmuebles</span>.numInstrumentoNotarial: </b> Número del instrumento notarial.
                                        </p> 
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">notariosPublicos</span>.notario:</b> Objecto con los datos del notario que realizó la operación.
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.notario</span>.curp: </b> CURP del notario que realizó el registro.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.notario</span>.numNotaria: </b> Número de la notaria a la que pertenece el notario.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.notario</span>.estado: </b> Clave del SAT para estado donde se localiza la notaria.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.notario</span>.adscripcion: (opcional)</b> Adscripción del notario.
                                        </p> 
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">notariosPublicos</span>.enajenantes: [ ]</b> Array con los objetos datos de quien(es) entrega(n) el inmueble.
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.enajenantes</span>.nombre: </b> Nombre o razón social del enajenante (quien entrega el inmueble).
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.enajenantes</span>.rfc: </b> RFC de quien entrega el inmueble.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.enajenantes</span>.porcentaje: </b> Porcentaje, solamente es necesario cuando hay mas de un enajenante (co-propiedad) y se entrega como texto representando su porcentaje en dos decimales ("50.10" sería 50.1%). <br />
                                            <span className="quote small">Cuando solamente hay un enajenante se debe de omitir este campo.</span>
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.enajenantes</span>.apellidoPaterno: (opcional)</b> Apellido paterno de la persona que enajena, cuando es persona física.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.enajenantes</span>.apellidoMaterno: (opcional)</b> Apellido paterno de la persona que enajena, cuando es persona física.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.enajenantes</span>.curp: (opcional)</b> CURP de quien enajena cuando es persona física.
                                        </p> 
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">notariosPublicos</span>.adquirientes: [ ]</b> Array con los objetos de quien(es) recibe(n) el inmueble. <br />
                                    <span className="small quote">Los adquirientes y enajenantes son identicos en sus propiedades</span>
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.adquirientes</span>.nombre: </b> Nombre o razón social del adquiriente (quien recibe el inmueble).
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.adquirientes</span>.rfc: </b> RFC de quien recibe el inmueble.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.adquirientes</span>.porcentaje: </b> Porcentaje, solamente es necesario cuando hay mas de un adquiriente (co-propiedad) y se entrega como texto representando su porcentaje en dos decimales ("50.10" sería 50.1%). <br />
                                            <span className="quote small">Cuando solamente hay un adquiriente se debe de omitir este campo.</span>
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.adquirientes</span>.apellidoPaterno: (opcional)</b> Apellido paterno de la persona que recibe, cuando es persona física.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.adquirientes</span>.apellidoMaterno: (opcional)</b> Apellido paterno de la persona que recibe, cuando es persona física.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">notariosPublicos.adquirientes</span>.curp: (opcional)</b> CURP de quien enajena cuando es persona física.
                                        </p> 
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>uuid: </b> El ID de la factura, la clave unica para identificar un CFDI.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>created: </b> Cuando fue creada la factura, indistinto de la fecha de la factura
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>json:</b> Representación del XML en JSON, en el caso de complementos de notario, contiene información importante de referencia del acta notarial y su participación.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">json.["cfdi:Complemento"]</span>["notariospublicos:NotariosPublicos"]:</b> Los datos del acta notarial facturada.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <b>xml:</b> El string del XML de la factura, el cual es el CFDI en si.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken:</b> El token del cliente emisor, usado para relacionar facturas con su emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>status:</b> <code>true | false</code> True significa que la factura está vigente, false cuando la factura está cancelada.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>sandbox:</b> <code>true | false</code> Si la factura fué generada por cliente de prueba o producción, false es de producción, true es una factura de prueba.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/referencia/cfdi-complemento-transporte`} previous={`/api/referencia/cfdi-complemento-pago`}/>
            </div>
            <div className="side">
                <CodeBlock title="Complemento Notario" requests={cfdiScripts.notarios.requests} response={cfdiScripts.notarios.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
